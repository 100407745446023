body {
    font-family: 'Courier Prime', monospace;
    background-color: #1c1c1c;
    color: #ff9900;
    padding: 0;
    margin: 0;
}

input[type=text], input[type=password] {
    border-radius: 7px;
    border: solid #ff9900;
    background-color: #1c1c1c00;
    color: #ff9900;
    font-family: 'Courier Prime', monospace;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    width: 17em;
    margin: 0 0 0.5em 0;
    padding: 0.3em 1.2em;
}

input[type=text]:hover, input[type=password]:hover {
    border: solid #af6900
}

button {
    border-style: none;
    border-radius: 2em;
    font-family: 'Courier Prime', monospace;
    font-size: 1.1em;
    font-weight: bold;
    color: #1c1c1c;
    background-color: #ff9900;
    width: 12em;
    margin: 0.5em 0 0 0;
    padding: 0.3em 1.2em;
    cursor: pointer;
}

button:hover {
    background-color: #af6900;
}

.login, .register, .footer {
    text-align: center;
}

.backslash-art {
    background-color:#2c2c2c;
    position: fixed;
    z-index: -1;
    width: 1px;
    height: 200%;
    
    top: 0px;
    left: 50%;
    /* transform: skewY(20deg); */
    transform: matrix(200, -0.6, 0.6, 1, 0, -850);

}